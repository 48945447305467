import React from 'react';

import { ProductCategory } from '@zego/flows/aggregators/constants';
import { BREAKPOINTS, unitTRBL } from '@zego/theme';

import View from '@zego/components/atoms/View';
import ExtendedProgressIndicator from '@zego/components/quickEstimates/ExtendedProgressIndicator';

interface EstimatesProgressIndicator {
  activeStep: string;
  productCategory: ProductCategory;
}

export const estimatesProgressIndicatorSteps: Record<string, string> = {
  yourDetails: 'Your Details',
  estimate: 'Estimate',
  loginSignup: 'Log in / Sign up',
  workProvider: 'Connect to Provider',
  refineQuote: 'Refine Quote',
  yourQuote: 'Your Quote',
  aboutSense: 'About Sense',
};

export const EstimatesProgressIndicatorCrumbs = (
  productCategory: ProductCategory,
): Array<string> => {
  if (productCategory === ProductCategory.GB_VAN) {
    return Object.values(estimatesProgressIndicatorSteps).filter(
      step => step !== estimatesProgressIndicatorSteps.aboutSense,
    );
  }
  if (productCategory !== ProductCategory.GB_PRIVATE_HIRE) {
    return Object.values(estimatesProgressIndicatorSteps).filter(
      step => step !== estimatesProgressIndicatorSteps.workProvider,
    );
  }
  return Object.values(estimatesProgressIndicatorSteps);
};

export const getIndexOfStep = (
  step: string,
  productCategory: ProductCategory,
): number => {
  return EstimatesProgressIndicatorCrumbs(productCategory).indexOf(step);
};

const EstimatesProgressIndicator = ({
  activeStep,
  productCategory,
  ...rest
}: EstimatesProgressIndicator): JSX.Element => {
  return (
    <View
      css={{
        margin: unitTRBL(0, -4),
        marginTop: '-80px',
        [BREAKPOINTS.medium]: { marginTop: '-148px' },
      }}
    >
      <ExtendedProgressIndicator
        {...rest}
        steps={EstimatesProgressIndicatorCrumbs(productCategory)}
        activeIndex={getIndexOfStep(activeStep, productCategory)}
      />
    </View>
  );
};

export default EstimatesProgressIndicator;
