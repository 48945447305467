import React, { useRef } from 'react';

import useWindowEvent from '@zego/hooks/useWindowEvent';
import { infoColor, primaryColor } from '@zego/theme';

export default function HomepageHeroBackground() {
  /** @type {React.MutableRefObject<SVGSVGElement>} */
  const svgRef = useRef();
  useWindowEvent('focus', () => {
    if (svgRef.current === undefined) return;
    svgRef.current.unpauseAnimations();
  });
  useWindowEvent('blur', () => {
    if (svgRef.current === undefined) return;
    svgRef.current.pauseAnimations();
  });
  const pauseSeconds = 0.1;
  const animateSeconds = 15;
  const totalSeconds = pauseSeconds + animateSeconds;

  const stagger = [-0.1, 0, -0.1, 0.2, 0.1];
  const splineStagger = [0, 0.1, 0, 0, 0];

  const defaultPos = [
    [1539, 146],
    [1271, 110],
    [408, 559],
    [1241, 409],
    [1504, 309],
  ];

  return (
    <svg
      width="100%"
      height="100%"
      version="1"
      preserveAspectRatio="xMidYMid slice"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="240 -106 1440 772"
      ref={svgRef}
    >
      <style>
        {`.st1{stroke-width:1}.st1,.st2{fill:none;stroke:#3C2E63}.st3{fill:${infoColor(
          5,
        )};stroke:#1a0c40;stroke-width:1}`}
      </style>
      <title>Hero_Home</title>
      <g id="Hero_Home">
        <rect
          id="Rectangle"
          x="240"
          y="-106"
          width="1440"
          height="772"
          fill={primaryColor(13)}
        />

        <path
          id="MotionPath2"
          className="st1"
          d="M1539 146c67 9 594 70 670 79l48 577c-419-45-1150-140-1357-179-30-4-61-21-78-52-18-26-26-60-18-91l66-316c13-66 73-105 139-96 127 23 304 49 530 78"
        />
        <path
          id="MotionPath1"
          className="st1"
          d="M1271 110a15938 15938 0 0 0 482 62l1108 131 48 577c-418-45-1801-218-2009-257-30-4-61-21-78-52-17-26-26-60-17-91l65-316c13-66 74-105 139-96 71 13 159 27 262 42"
        />
        <path
          id="MotionPath4"
          className="st2"
          d="M408 559c197-179 357-317 478-413 23-21 56-32 91-25 31 4 63 20 80 46l196 257c41 53 30 124-19 168-57 46-142 116-254 210l-815-20 243-223"
        />
        <path
          id="MotionPath3"
          className="st2"
          d="M1241 409l12 15c41 53 30 124-19 168-57 46-142 116-254 210l-815-20c313-291 553-503 721-636 23-21 56-32 91-25 31 4 63 20 80 46l184 242"
        />
        <path
          id="Path_2_"
          className="st2"
          d="M1371-385l155 56c29 10 56 32 68 65 12 29 15 65 1 93l-119 300c-24 62-91 90-153 70C1069 105 681-9 160-145l1211-240z"
        />
        <path
          id="MotionPath5"
          className="st1"
          d="M1504 309c22-13 46-21 71-21l1149-108c73 454-440 720-440 720l-836-176c-11-81-21-162-30-244-7-44 4-93 38-130 14-16 30-30 48-41"
        />
        <path
          id="Path_4_"
          className="st2"
          d="M1512 790c124-153 219-267 283-342 27-35 71-60 121-60 46-3 95 11 127 44l350 311c72 63 77 168 19 243l-147 175-753-371z"
        />
        <path
          id="Path_5_"
          className="st1"
          d="M795 827c258-364 534-714 827-1050 333-383 1263 473 912 889-86 103-171 207-255 313-336 424-1688 135-1484-152z"
        />
        {[1, 2, 3, 4, 5].map((x, idx) => {
          const startTime = (pauseSeconds + stagger[idx]) / totalSeconds;
          const endTime = Math.min(
            (totalSeconds - stagger[idx]) / totalSeconds,
            1,
          );
          const keyTimes = [0, startTime, endTime].join(';');
          return (
            <React.Fragment key={x}>
              <circle key={x} id={`Oval-${x}`} className="st3" r="4">
                <animateMotion
                  dur={`${totalSeconds}s`}
                  repeatCount="indefinite"
                  keySplines={`0 0 0 0;${0.7 + splineStagger[idx]} 0 0.2 1`}
                  calcMode="spline"
                  fill="freeze"
                  keyTimes={keyTimes}
                  keyPoints="1;1;0"
                >
                  <mpath href={`#MotionPath${x}`} />
                </animateMotion>
              </circle>

              {/* Fallback for browsers that don't support SMIL (IE, Edge, Opera mini https://caniuse.com/#feat=svg-smil) */}
              <circle
                key={`fallback${x}`}
                id={`fallback-oval-${x}`}
                className="st3"
                cx={defaultPos[idx][0]}
                cy={defaultPos[idx][1]}
                r="6"
              >
                <animate
                  attributeName="opacity"
                  from="1"
                  to="0"
                  dur="1ms"
                  fill="freeze"
                />
              </circle>
            </React.Fragment>
          );
        })}
      </g>
    </svg>
  );
}
