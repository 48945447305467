import React from 'react';

import { Global, css } from '@emotion/core';

import modernEraBlackWoff from '@zego/static/typefaces/ModernEra-Black.woff';
import modernEraBlackWoff2 from '@zego/static/typefaces/ModernEra-Black.woff2';
import modernEraBoldWoff from '@zego/static/typefaces/ModernEra-Bold.woff';
import modernEraBoldWoff2 from '@zego/static/typefaces/ModernEra-Bold.woff2';
import modernEraBoldItalicWoff from '@zego/static/typefaces/ModernEra-BoldItalic.woff';
import modernEraBoldItalicWoff2 from '@zego/static/typefaces/ModernEra-BoldItalic.woff2';
import modernEraItalicWoff from '@zego/static/typefaces/ModernEra-Italic.woff';
import modernEraItalicWoff2 from '@zego/static/typefaces/ModernEra-Italic.woff2';
import modernEraMediumWoff from '@zego/static/typefaces/ModernEra-Medium.woff';
import modernEraMediumWoff2 from '@zego/static/typefaces/ModernEra-Medium.woff2';
import modernEraRegularWoff from '@zego/static/typefaces/ModernEra-Regular.woff';
import modernEraRegularWoff2 from '@zego/static/typefaces/ModernEra-Regular.woff2';
import { disabledColor, modernEraFamily } from '@zego/theme';

const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Modern Era';
        font-display: swap;
        src: url("${modernEraRegularWoff2}") format("woff2"), url("${modernEraRegularWoff}") format("woff");
      }

      @font-face {
        font-family: 'Modern Era';
        font-weight: 500;
        font-display: swap;
        src: url("${modernEraMediumWoff2}") format("woff2"), url("${modernEraMediumWoff}") format("woff");
      }

      @font-face {
        font-family: 'Modern Era';
        font-weight: 700;
        font-display: swap;
        src: url("${modernEraBoldWoff2}") format("woff2"), url("${modernEraBoldWoff}") format("woff");
      }

      @font-face {
        font-family: 'Modern Era';
        font-weight: 900;
        font-display: swap;
        src: url("${modernEraBlackWoff2}") format("woff2"), url("${modernEraBlackWoff}") format("woff");
      }

      @font-face {
        font-family: 'Modern Era';
        font-style: italic;
        font-display: swap;
        src: url("${modernEraItalicWoff2}") format("woff2"), url("${modernEraItalicWoff}") format("woff");
      }

      @font-face {
        font-family: 'Modern Era';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url("${modernEraBoldItalicWoff2}") format("woff2"), url("${modernEraBoldItalicWoff}") format("woff");
      }

      html {
        box-sizing: border-box;
      }
      *,
      *:before,
      *:after {
        -webkit-overflow-scrolling: touch;
        box-sizing: inherit;
      }

      body,
      html {
        margin: 0;
        padding: 0;
        color: ${disabledColor(10)};
        font-family: ${modernEraFamily};
      }

      body {
        font-size: 16px;
        line-height: 1.4;
        overflow: auto;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 400;
        margin: 0;
      }

      /* Make clicks pass-through */
      #nprogress {
        pointer-events: none;
      }

      #nprogress .bar {
        background: #29d;

        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;

        width: 100%;
        height: 2px;
      }

      /* Fancy blur effect */
      #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px #29d, 0 0 5px #29d;
        opacity: 1.0;

        -webkit-transform: rotate(3deg) translate(0px, -4px);
            -ms-transform: rotate(3deg) translate(0px, -4px);
                transform: rotate(3deg) translate(0px, -4px);
      }

      /* Removes Salesforce Minimized Button */
      .minimizedContainer {
        display: none;
      }
    `}
  />
);

export default GlobalStyles;
