import React from 'react';

import { ThemeProvider } from 'emotion-theming';

import {
  BREAKPOINTS,
  INNER_GUTTER,
  PALETTE,
  modernEraFamily,
  primaryColor,
  unit,
  white,
} from '@zego/theme';

export default function DefaultTheme({ children }) {
  return (
    <ThemeProvider
      theme={{
        unit,
        gutter: INNER_GUTTER,
        breakpoints: BREAKPOINTS,
        fontFamily: modernEraFamily,
        tintColor: primaryColor(),
        colours: white,
        palette: PALETTE,
      }}
    >
      {children}
    </ThemeProvider>
  );
}
