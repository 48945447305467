import React, { useContext } from 'react';

import PageContext from '@zego/context/PageContext';
import DefaultTheme from '@zego/themes/DefaultTheme';

import Head from '@zego/components/Head';

import esOpenGraph from '@zego/static/images/opengraph-es.png';
import enOpenGraph from '@zego/static/images/opengraph-uk.png';

function addDomainIfMissing(url, domain) {
  if (url.startsWith('http')) {
    return url;
  }
  return `${domain}${url}`;
}

function localBusinessStructuredData(locale) {
  if (locale === 'en-gb')
    return {
      '@context': 'https://schema.org',
      '@type': 'InsuranceAgency',
      name: 'Zego',
      image:
        'https://static.zego.com/_next/static/images/Logo-d12c58c0bd8be86c8f9a2821c735258f.svg',
      '@id': '',
      url: 'https://www.zego.com/',
      telephone: '02033089800',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '7th Floor Exchange House, 12 Primrose Street',
        addressLocality: 'London',
        postalCode: 'EC2A 2BQ',
        addressCountry: 'GB',
      },
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '09:00',
          closes: '20:00',
        },
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Saturday', 'Sunday'],
          opens: '09:00',
          closes: '19:00',
        },
      ],
    };
}

const CoreLayout = ({
  title = 'Zego',
  description = 'Why pay for insurance when you don’t use it? Zego offers flexible insurance cover for food and courier delivery drivers when they are working. Save money with Zego.',
  ogImage = null,
  ogImageWidth = 1024,
  ogImageHeight = 536,
  structuredData = null,
  children,
}) => {
  const { language, locale } = useContext(PageContext);

  return (
    <DefaultTheme>
      <Head>
        <title>{title}</title>
        <meta key="description" name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={locale} />

        <meta
          name="image"
          property="og:image"
          content={
            ogImage ? ogImage : language === 'es' ? esOpenGraph : enOpenGraph
          }
        />
        <meta property="og:image:width" content={ogImageWidth} />
        <meta property="og:image:height" content={ogImageHeight} />
        {/* Twitter-specific tags */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:site" content="@zegocover" />
        <meta name="twitter:creator" content="@zegocover" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:image"
          content={addDomainIfMissing(
            ogImage ? ogImage : language === 'es' ? esOpenGraph : enOpenGraph,
            'https://www.zego.com',
          )}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(localBusinessStructuredData(locale)),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Head>

      {children}
    </DefaultTheme>
  );
};

export default CoreLayout;
