import React from 'react';
import { ReactRelayContext } from 'react-relay';
import { UIDReset } from 'react-uid';

import App from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import Router from 'next/router';

import Cookies from 'js-cookie';
import forEach from 'lodash/forEach';
import NProgress from 'nprogress';
import { v4 as uuidv4 } from 'uuid';

import { getAuthToken, setAuthToken } from '@zego/auth';
import PageContext from '@zego/context/PageContext';
import { FormContext } from '@zego/forms/Form';
import { LOCALE_FORMAT } from '@zego/i18n/constants';
import I18nProvider from '@zego/i18n/I18nProvider';
import { getLangFromCountry, getLocaleFromCountry } from '@zego/i18n/utils';
import GlobalStyles from '@zego/layouts/GlobalStyles';
import createRelayEnvironment from '@zego/lib/createRelayEnvironment';
import ZegoError from '@zego/pages/_error';
import SessionManager from '@zego/session/SessionManager';
import { TrackingContext } from '@zego/tracking';
import { getCountryFromPath, isBrowser } from '@zego/utils';

import PageBootstrap from '@zego/components/PageBootstrap';

const { publicRuntimeConfig } = getConfig();

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

Router.events.on('routeChangeComplete', () => {
  if (window._sift) {
    const { customerNumber, sessionId } = window.siftDataLayer;
    window._sift.push(['_setAccount', publicRuntimeConfig.SIFT_BEACON_KEY]);
    window._sift.push(['_setUserId', customerNumber]);
    window._sift.push(['_setSessionId', sessionId]);
    window._sift.push(['_trackPageview']);
  }
});

const clientServerRedirect = (ctx, location) => {
  if (isBrowser()) {
    Router.replace(location.href, location.as ? location.as : location.href, {
      shallow: false,
    });
  } else {
    ctx.res.redirect(location.as ? location.as : location.href);
    ctx.res.end();
  }
};

const sessionCookie = (req, res) => {
  const sid = Cookies.get('sid');
  if (!sid || sid.length === 0) {
    if (isBrowser()) {
      Cookies.set('sid', uuidv4(), { secure: true });
    } else {
      req.cookies.sid = uuidv4();
      res.cookie('sid', req.cookies.sid, { secure: true });
    }
  }
};

export default class ZegoApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    const pageCountry = getCountryFromPath(ctx.pathname);
    const detectedCountry = (
      ctx.req?.header('cf-ipcountry') || 'gb'
    ).toLowerCase();

    const hasDismissedMismatchedCountryBanner = ctx.req
      ? ctx.req.cookies['hasDismissedMismatchedCountryBanner']
      : Cookies.get('hasDismissedMismatchedCountryBanner') || false;

    let statusCode = 200;
    let headers;

    sessionCookie(ctx.req, ctx.res);

    if (Component.getInitialProps) {
      try {
        pageProps = await Component.getInitialProps({
          ...ctx,
          query: { ...ctx.query, country: pageCountry },
        });

        if (pageProps.pageHeaders) {
          [statusCode, headers = {}] = pageProps.pageHeaders;
          const redirect = headers?.Location?.as !== ctx.asPath;
          if (!isBrowser()) {
            forEach(headers, (value, key) => {
              ctx.res.setHeader(key, value);
            });
          }
          if (headers.Location && redirect) {
            clientServerRedirect(ctx, headers.Location);
          }
          if (ctx.res) {
            ctx.res.statusCode = statusCode;
          }
        }
      } catch (err) {
        if (ctx.res) {
          ctx.res.statusCode = 500;
        }
        return { error: err, statusCode: 500 };
      }
    }

    return {
      pageProps,
      detectedCountry,
      statusCode,
      hasDismissedMismatchedCountryBanner,
      country: pageCountry,
    };
  }

  // Workaround for this issue https://github.com/vercel/next.js/pull/16477
  // This can be removed once we update to Next.js 9.5
  componentDidMount() {
    Router.beforePopState(({ as }) => {
      window.location.href = as;
    });

    setAuthToken(getAuthToken());
  }

  render() {
    const {
      Component,
      detectedCountry,
      country,
      hasDismissedMismatchedCountryBanner,
      pageProps,
      router,
      statusCode,
      error,
    } = this.props;

    const environment = createRelayEnvironment({
      records: pageProps && pageProps.queryRecords,
      request: null,
    });

    const url = new URL(router.asPath, 'http://www.zego.com/');

    return (
      <>
        <Head>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="viewport"
            content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Head>
        <UIDReset prefix="zego-">
          <ReactRelayContext.Provider
            value={{
              environment,
            }}
          >
            <SessionManager>
              <PageContext.Provider
                value={{
                  language: getLangFromCountry(country),
                  locale: getLocaleFromCountry(country, LOCALE_FORMAT.IETF),
                  detectedCountry,
                  hasDismissedMismatchedCountryBanner,
                  country,
                }}
              >
                <TrackingContext.Provider
                  value={{
                    urlPath: url.pathname,
                    urlQueryString: url.search,
                    urlHash: url.hash,
                  }}
                >
                  <I18nProvider language={getLangFromCountry(country)}>
                    <FormContext.Provider value={null}>
                      <PageBootstrap />
                      <GlobalStyles />
                      {statusCode >= 400 ? (
                        <ZegoError
                          err={error}
                          statusCode={statusCode}
                          country={country}
                        />
                      ) : (
                        <Component environment={environment} {...pageProps} />
                      )}
                    </FormContext.Provider>
                  </I18nProvider>
                </TrackingContext.Provider>
              </PageContext.Provider>
            </SessionManager>
          </ReactRelayContext.Provider>
        </UIDReset>
      </>
    );
  }
}
