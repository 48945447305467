import styled from '@emotion/styled';

import { MAX_CONTENT_WIDTH, OUTER_GUTTER } from '@zego/theme';

const ContentContainer = styled.div({
  maxWidth: OUTER_GUTTER + MAX_CONTENT_WIDTH + OUTER_GUTTER,
  paddingLeft: OUTER_GUTTER,
  paddingRight: OUTER_GUTTER,
  margin: '0 auto',
});

export default ContentContainer;
