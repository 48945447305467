/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/* @relayHash aab75f6358e2c612a13d9c5fb6b23191 */

import { ConcreteRequest } from "relay-runtime";
export type SessionManager_resetSessionQueryVariables = {};
export type SessionManager_resetSessionQueryResponse = {
    readonly viewer: {
        readonly session: {
            readonly id: string;
            readonly token: string;
            readonly expiresAt: string;
        };
        readonly currentUser: {
            readonly customerNumber: string;
        } | null;
    };
};
export type SessionManager_resetSessionQuery = {
    readonly response: SessionManager_resetSessionQueryResponse;
    readonly variables: SessionManager_resetSessionQueryVariables;
};



/*
query SessionManager_resetSessionQuery {
  viewer {
    session {
      id
      token
      expiresAt
    }
    currentUser {
      customerNumber
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "session",
  "storageKey": null,
  "args": null,
  "concreteType": "Session",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "token",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expiresAt",
      "args": null,
      "storageKey": null
    }
  ]
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "customerNumber",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SessionManager_resetSessionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentUser",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SessionManager_resetSessionQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentUser",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SessionManager_resetSessionQuery",
    "id": null,
    "text": "query SessionManager_resetSessionQuery {\n  viewer {\n    session {\n      id\n      token\n      expiresAt\n    }\n    currentUser {\n      customerNumber\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'e3fd4c8589fd44755bb36594bbd8f774';
export default node;
