import React from 'react';

import { css } from '@emotion/core';
import { CSSObject } from '@emotion/styled';
import RouterLink from 'next/link';

import { withTheme } from 'emotion-theming';

export interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  as?: URL;
  theme: { tintColor?: string };
  external?: boolean;
  css?: CSSObject;
  disabled?: boolean;
  /**
   * @warning Always include a href, using null | undefined has been deprecated
   */
  href?: string | null;
}

const Link: React.FC<LinkProps> = ({
  theme,
  color,
  css: className,
  href = null,
  as = null,
  external = false,
  children,
  ...otherProps
}) => {
  const styles = css({
    textDecoration: 'none',
    color: color || theme.tintColor,
    cursor: 'pointer',
  });

  const externalMatch = href
    ? /^(ftp|http|https):\/\/[^ "]+$/.test(href)
    : false;
  const isExternal = external || externalMatch;
  const rel =
    otherProps?.target === '_blank' ? 'noopener noreferrer' : otherProps.rel;

  if (isExternal) {
    return (
      <a css={[styles, className]} {...otherProps} href={`${href}`} rel={rel}>
        {children}
      </a>
    );
  }

  return href ? (
    <RouterLink href={href} as={as ? as : href} passHref prefetch={false}>
      <a css={[styles, className]} {...otherProps}>
        {children}
      </a>
    </RouterLink>
  ) : (
    // eslint-disable-next-line no-script-url, jsx-a11y/anchor-is-valid
    <a css={[styles, className]} {...otherProps} href="javascript:;">
      {children}
    </a>
  );
};

export default withTheme(Link);
