import React from 'react';

import { disabledColor } from '@zego/theme';

const Chevron = ({ color = disabledColor(10), direction = 'right' }) => {
  const rotation =
    {
      down: 0,
      left: 90,
      up: 180,
      right: 270,
    }[direction] || 0;
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <path
        d="M15.571 9L17 10.414l-4.444 4.4a.791.791 0 0 1-1.112 0L7 10.414 8.429 9 12 12.536 15.571 9z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Chevron;
