import React from 'react';

import { Trans, t } from '@lingui/macro';

import useI18n from '@zego/i18n/useI18n';
import CoreLayout from '@zego/layouts/CoreLayout';
import { getColWidthCalc, infoColor, unit, white } from '@zego/theme';
import { pathWithCountry } from '@zego/utils/route.ts';

import ArrowLink from '@zego/components/ArrowLink';
import ContainerSection from '@zego/components/atoms/ContainerSection';
import H1 from '@zego/components/atoms/H1';
import H2 from '@zego/components/atoms/H2';
import RegularText from '@zego/components/atoms/RegularText';
import Spacer from '@zego/components/atoms/Spacer';
import View from '@zego/components/atoms/View';
import ContentContainer from '@zego/components/ContentContainer';
import Logo from '@zego/components/header/Logo';
import HomepageHeroBackground from '@zego/components/HomepageHero/HomepageHeroBackground.js';
import Link from '@zego/components/Link';

const mapStatusCodeToErrorMessage = statusCode => {
  switch (statusCode) {
    case 500:
      return t(
        'Error / 500',
      )`Sorry, There was a server error. It's not you. It's us.`;
    case 404:
      return t('Error / 404')`Sorry this page is missing.`;
    default:
      return t('Error / Not handled')`Sorry, something went wrong.`;
  }
};

const Error = ({ statusCode, country }) => {
  const { i18n } = useI18n();

  return (
    <CoreLayout
      title={i18n._(t('Error / Meta title')`Sorry, something went wrong.`)}
    >
      <View css={{ position: 'relative', height: '100vh' }}>
        <View
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <HomepageHeroBackground />
        </View>
        <ContainerSection>
          <ContentContainer
            css={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Link
              href={pathWithCountry(country, '/')}
              css={{
                flexGrow: 0,
                display: 'flex',
                paddingTop: unit(4),
                paddingBottom: unit(4),
                width: unit(4) * 2 + 100,
                fill: white,
              }}
            >
              <Logo />
            </Link>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
                paddingBottom: unit(24),
              }}
            >
              <div
                css={{
                  maxWidth: getColWidthCalc(12, 8, 20),
                }}
              >
                {statusCode ? (
                  <H1 css={{ color: white }}>{statusCode}</H1>
                ) : null}
                <H2 css={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  {i18n._(mapStatusCodeToErrorMessage(statusCode))}
                </H2>
                <Spacer height={unit(3)} />
                <RegularText>
                  {country !== 'ie' ? (
                    <Trans id="Error / If you need to get in touch">
                      If you need to get in touch please call <br />
                      <a
                        css={{ color: infoColor(5), textDecoration: 'none' }}
                        href={`tel:${i18n._(t('Error / Tel:')`02033089800`)}`}
                      >
                        020 3308 9800
                      </a>{' '}
                      or email{' '}
                      <a
                        css={{ color: infoColor(5), textDecoration: 'none' }}
                        href={`mailto:${i18n._(
                          t('Error / Mailto:')`support@zego.com`,
                        )}`}
                      >
                        support@zego.com
                      </a>
                    </Trans>
                  ) : (
                    <Trans id="Error / If you need to get in touch / IE">
                      If you need to get in touch please call <br />
                      <a
                        css={{ color: infoColor(5), textDecoration: 'none' }}
                        href={`tel:${i18n._(
                          t('Error / Tel / IE:')`35315260877`,
                        )}`}
                      >
                        +353 1 526 0877
                      </a>{' '}
                      or email{' '}
                      <a
                        css={{ color: infoColor(5), textDecoration: 'none' }}
                        href={`mailto:${i18n._(
                          t('Error / Mailto / IE:')`support.ie@zego.com`,
                        )}`}
                      >
                        support.ie@zego.com
                      </a>
                    </Trans>
                  )}
                </RegularText>
                <Spacer height={unit(6)} />
                <View
                  css={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ArrowLink color={white} href="/">
                    <Trans id="Error / Take me home">
                      Take me to the home page
                    </Trans>
                  </ArrowLink>
                </View>
                <Spacer height={unit(4)} />
              </div>
            </div>
          </ContentContainer>
        </ContainerSection>
      </View>
    </CoreLayout>
  );
};

export default Error;
