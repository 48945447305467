import getStorage from '@zego/getStorage';
import { isBrowser } from '@zego/utils';

export const EVENT_TYPES = {
  LEAD: 'Lead',
  REGISTRATION: 'Registration',
  MAGIC_LINK: 'MAGIC_LINK',
  ADD_PAYMENT: 'AddPayment',
  PURCHASE: 'Purchase',
  CONFIGURE_PRODUCT: 'ConfigureProduct',
};

const SENSITIVE_PARAMS = ['email', 'password'];

function filterSensitiveParams(data) {
  return Object.keys(data)
    .filter(key => !SENSITIVE_PARAMS.includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
}

export function trackEvent(name, payload = {}) {
  const filteredPayload = filterSensitiveParams(payload);

  if (isBrowser() && typeof window.analytics === `object`) {
    window.analytics.track(name, filteredPayload);
  }

  if (process.env.NODE_ENV !== `production`) {
    // eslint-disable-next-line no-console
    console.log(`[Track Event] ${name}`, payload);
  }
}

export function trackPageview(location, { name, ...payload } = {}) {
  const storage = getStorage('session');

  const filteredPayload = filterSensitiveParams(payload);

  const mergedPayload = {
    $urlHash: location.hash,
    $urlPath: location.pathname,
    $urlQueryString: location.search,
    isSessionStartPage: false,
    ...filteredPayload,
  };

  if (storage && !storage.getItem('hasTrackedSessionStart')) {
    mergedPayload.isSessionStartPage = true;
    storage.setItem('hasTrackedSessionStart', true);
  }

  /* HERE BE DRAGONS
   * The Segment analytics.page function automatically collects
   * The document title (and other things) from the browser APIs.
   * Problem is that Next's API for updating things like document.title
   * don't trigger the change BEFORE we call this function, so it's always
   * one page behind.
   *
   * Our workaround is to do the actual pageview tracking one tick later, to
   * Give everything time to update properly.
   *
   * Once next.js fixes this, we can remove the setTimeout call.
   */
  setTimeout(() => {
    if (process.env.NODE_ENV !== `production`) {
      // eslint-disable-next-line no-console
      console.log(`[Track Pageview] ${location.pathname}`, mergedPayload);
    }
    if (typeof window.analytics === `object`) {
      window.analytics.page(name, mergedPayload);
    }
  }, 0);
}

export function identifyUser(id) {
  if (process.env.NODE_ENV !== `production`) {
    if (typeof id === 'object') {
      // eslint-disable-next-line no-console
      console.log(`[Identify User] (anonymous user)`);
      console.table(id);
    } else {
      // eslint-disable-next-line no-console
      console.log(`[Identify User] ${id}`);
    }
  }

  if (typeof window.analytics === `object`) {
    window.analytics.identify(id);
  }
}

export function reset() {
  if (process.env.NODE_ENV !== `production`) {
    // eslint-disable-next-line no-console
    console.log(`[Resetting user]`);
  }

  if (typeof window.analytics === `object`) {
    window.analytics.reset();
  }
}
