import styled from '@emotion/styled';

import { primaryColor } from '@zego/theme';

const ContainerSection = styled.section({
  position: 'relative',
  height: '100%',
  '&::before': {
    position: 'absolute',
    content: '""',
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: primaryColor(13),
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
});

export default ContainerSection;
