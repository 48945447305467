// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { EstimateState } from '@zego/enums';
import getStorage from '@zego/getStorage';

import { estimatesProgressIndicatorSteps } from '@zego/components/EstimatesProgressIndicator';

/**
 * Turn the state of an EstimateResult into an index
 * Returns undefined if no matching case
 * @param {EstimateState} state
 */
export function getEstimatesProgressIndicatorStepForEstimateResult(
  state: EstimateState,
): string | undefined {
  switch (state) {
    case EstimateState.VEHICLE_DETAILS_NEEDED:
    case EstimateState.LEAD_DETAILS_NEEDED:
      return estimatesProgressIndicatorSteps.yourDetails;
    case EstimateState.OPTION_CHOICE_NEEDED:
      return estimatesProgressIndicatorSteps.estimate;
    case EstimateState.USER_NEEDED:
      return estimatesProgressIndicatorSteps.loginSignup;
    case EstimateState.COMPLETED:
      return estimatesProgressIndicatorSteps.refineQuote;
    default:
      return undefined;
  }
}

/**
 * Get promotionRefUrl from local storage for promotion landing pages
 * @returns {null | string} promotionRefUrl
 */
export function getPromotionRefUrl(): string | null {
  const storage = getStorage('local');
  return storage.getItem('promotionRefUrl');
}

/**
 * Set promotionRefUrl in local storage for promotion landing pages
 * @param {string} name The value to set `promotionRefUrl`
 */
export function setPromotionRefUrl(name): void {
  const storage = getStorage('local');

  if (name) {
    storage.setItem('promotionRefUrl', name);
  }
}

/**
 * Remove promotionRefUrl from local storage
 */
export function removePromotionRefUrl(): void {
  const storage = getStorage('local');

  try {
    storage.removeItem('promotionRefUrl');
  } catch (e) {
    console.error(e);
  }
}
