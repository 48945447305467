// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from 'react';
import { fetchQuery, graphql } from 'react-relay';

import { getAuthToken, getExpiryTime, setAuthToken } from '@zego/auth';
import useLogout from '@zego/hooks/useLogout';
import usePrevious from '@zego/hooks/usePrevious';
import useRelay from '@zego/hooks/useRelay';
import { getNextUrl } from '@zego/storage/getNextUrl';
import { useTracking } from '@zego/tracking';
import { isBrowser } from '@zego/utils';

import SessionContext from './SessionContext';

/*
 * Runs a query that gets the session data from the server.
 * If a user has deleted their auth token, this will generate a new session
 * ID for them, which will break the tracking trail -- e.g. Amplitude will
 * represent subsequent events as being from a new user.
 */
async function resetSession(environment) {
  const query = graphql`
    query SessionManager_resetSessionQuery {
      viewer {
        session {
          id
          token
          expiresAt
        }
        currentUser {
          customerNumber
        }
      }
    }
  `;
  fetchQuery(environment, query, {});
}

export default function SessionManager(props) {
  const { environment } = useRelay();
  const { reset, trackEvent } = useTracking();
  const logout = useLogout();

  const [{ session, customerNumber }, setData] = useState({
    session: null,
    customerNumber: null,
  });

  const prevCustomerNumber = usePrevious(customerNumber);
  const [clientToken] = useState(getAuthToken());
  const prevClientToken = usePrevious(clientToken);

  function isPublic() {
    return window.location.pathname.includes('magic-link');
  }

  function isFleetDriverSelfOnboading() {
    return window.location.pathname.includes('fleet/driver/self-onboard');
  }

  useEffect(() => {
    if (!isBrowser()) return;
    if (isPublic()) return;
    if (isFleetDriverSelfOnboading()) return;

    const interval = setInterval(() => {
      const expiresAt = getExpiryTime(getAuthToken());

      const isTokenExpired = expiresAt === null || Date.now() > expiresAt;

      if (isTokenExpired) {
        trackEvent('Website: User logged out because token expired', {
          customerNumber: customerNumber || 'anonymous user',
        });
        logout(getNextUrl() || '/').then(() => clearInterval(interval));
      }
    }, 5 * 1000);

    return () => clearInterval(interval);
  }, [clientToken]);

  useEffect(() => {
    if (isFleetDriverSelfOnboading()) return;

    // User has deleted cookie containing their auth token
    if (prevClientToken && !clientToken) {
      (async () => {
        await resetSession(environment);
        reset();
      })();
    }
  }, [
    clientToken,
    reset,
    prevClientToken,
    prevCustomerNumber,
    customerNumber,
    environment,
  ]);

  const token = session ? session.token : undefined;

  // Token has changed
  useEffect(() => {
    if (token) {
      setAuthToken(`Bearer ${token}`);
    }
  }, [token]);

  const updateSession = useCallback((session, customerNumber) => {
    setData({ session, customerNumber });
  }, []);

  return <SessionContext.Provider value={updateSession} {...props} />;
}
