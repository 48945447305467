import getStorage from './getStorage';

const NEXT_URL_KEY = 'nextUrl';

export function setNextUrl(path: string): void {
  const storage = getStorage('local');
  storage && storage.setItem(NEXT_URL_KEY, path);
}

export function getNextUrl(): string | void {
  const storage = getStorage('local');
  const storageItem = storage.getItem(NEXT_URL_KEY);
  if (storage && typeof storageItem === 'string') {
    return storageItem;
  }
}
