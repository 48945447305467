// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';

import styled from '@emotion/styled';

import { take, takeRight } from 'lodash';

import Chevron from '@zego/static/svgs/Chevron';
import { BREAKPOINTS, disabledColor, unitTRBL } from '@zego/theme';

import View from '@zego/components/atoms/View';

const ExtendedProgressIndicator = ({ steps, activeIndex, ...rest }) => (
  <Wrapper {...rest}>
    <LeftProgressItems>
      {take(steps, activeIndex).map((step, idx) => (
        <React.Fragment key={idx}>
          <View key={idx}>{step}</View>
          <ChevronWraper>
            <Chevron direction="right" color={disabledColor(7)} />
          </ChevronWraper>
        </React.Fragment>
      ))}
    </LeftProgressItems>
    <CurrentProgressItem>{steps[activeIndex]}</CurrentProgressItem>
    <RightProgressItems>
      {takeRight(steps, steps.length - activeIndex - 1).map((step, idx) => (
        <React.Fragment key={idx}>
          <ChevronWraper>
            <Chevron
              direction="right"
              color={idx === 0 ? disabledColor(7) : disabledColor(4)}
            />
          </ChevronWraper>
          <View>{step}</View>
        </React.Fragment>
      ))}
    </RightProgressItems>
  </Wrapper>
);

const ChevronWraper = styled(View)({
  display: 'flex',
  alignItems: 'center',
  margin: unitTRBL(0, 1),
});

const Wrapper = styled(View)({
  width: '100%,',
  zIndex: 1000,
  position: 'relative',
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  fontSize: 14,
  [BREAKPOINTS.medium]: {
    margin: 'auto',
    width: 'fit-content',
  },
});

const LeftProgressItems = styled(View)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
  overflow: 'hidden',
  flexBasis: '50%',
  alignItems: 'center',
  maxWidth: 'max-content',
  fontWeight: 500,
  '&:before': {
    // This gives a 32px padding before the left side of the breadcrumbs
    // but gets pushed off screen if there is too many items to fit
    content: '""',
    width: 32,
  },
});

const CurrentProgressItem = styled(View)({
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  flexBasis: 'auto',
});

const RightProgressItems = styled(View)({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  flexBasis: '50%',
  maxWidth: 'max-content',
  '&:after': {
    // This gives a 32px padding after the right side of the breadcrumbs
    // but gets pushed off screen if there is too many items to fit
    content: '""',
    width: 32,
  },
  color: disabledColor(4),
});

export default ExtendedProgressIndicator;
