import React from 'react';

import { withTheme } from 'emotion-theming';

import ArrowLinkDecoration from '@zego/components/ArrowLinkDecoration';
import Link from '@zego/components/Link';

const ArrowLink = props => (
  <>
    <Link
      {...props}
      css={{
        paddingRight: 12,
        color: props.color || props.theme.tintColor,
        textDecoration: 'none',
        ':hover, :focus': { textDecoration: 'underline' },
      }}
    />
    <ArrowLinkDecoration color={props.color} />
  </>
);

export default withTheme(ArrowLink);
