import styled from '@emotion/styled';

import { BREAKPOINTS, baseText, disabledColor } from '@zego/theme';

const H2 = styled.h2(baseText, {
  fontWeight: 700,
  fontSize: 36,
  color: disabledColor(10),
  lineHeight: 0.96,
  letterSpacing: -1.35,
  [BREAKPOINTS.small]: {
    fontSize: 42,
  },
});

export default H2;
