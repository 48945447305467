// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import { fetchQuery, graphql } from 'react-relay';

import getConfig from 'next/config';

import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { clearAuthToken, getAuthToken, setAuthToken } from '@zego/auth';
import { removePromotionRefUrl } from '@zego/flows/quickEstimates/utils';
import useNavigation from '@zego/hooks/useNavigation';
import useRelay from '@zego/hooks/useRelay';
import sentry from '@zego/sentry';
import { useTracking } from '@zego/tracking';
import clearSessionRedirect from '@zego/utils/clearSessionRedirect';

const { captureException } = sentry();

const { publicRuntimeConfig } = getConfig();

function resetSession(environment) {
  const query = graphql`
    query useLogout_resetSessionQuery {
      viewer {
        session {
          id
          token
          expiresAt
        }
        currentUser {
          customerNumber
        }
      }
    }
  `;
  // This looks crazy, but we want to fetch the new session without a cookie.
  clearAuthToken();
  sessionLogout();

  // We need to ensure the authToken is only reset once the session reset query
  // has been executed
  const result = fetchQuery(environment, query, {}).then(() =>
    resetAuthToken(),
  );

  // Cleanup promotionRefUrl from local storage on logout
  removePromotionRefUrl();

  return result;
}

function resetAuthToken() {
  // We set it back because we don't want to trigger SessionManager's own
  // cookie-reset behaviour. When fetchQuery completes it'll trigger an effect
  // which sets the new cookie, but for the duration of the query we should keep
  // using the old one.
  const authToken = getAuthToken();
  return setAuthToken(authToken);
}

function sessionLogout() {
  return fetch(`${publicRuntimeConfig.REST_API_ENDPOINT}/logout`)
    .then(() => {
      console.log('Session logout successful');
    })
    .catch(error => {
      captureException(error);
    });
}

function myZegoLogout() {
  clearSessionRedirect();

  // TODO: do something better(?)
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = '/my-zego/api/auth/signout/';
  document.body.appendChild(iframe);
}

export const signOut = async (): Promise<void> => {
  const cognitoCookies = document.cookie
    .split('; ')
    .filter(x => x.includes('CognitoIdentityServiceProvider'));
  cognitoCookies.forEach(cookie => Cookies.remove(cookie.split('=')[0]));

  const cognitoLocalStorage = Object.keys(localStorage).filter(x =>
    x.includes('CognitoIdentityServiceProvider'),
  );
  cognitoLocalStorage.forEach(cognitoItemKey =>
    localStorage.removeItem(cognitoItemKey),
  );

  try {
    await Auth.signOut();
    window.location.href = '/';
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

function useLogout(): (nextUrl?: string) => Promise<void> {
  const { enabledAuth0MyAccount } = useFlags();
  const { environment } = useRelay();
  const { trackEvent, reset } = useTracking();
  const navigation = useNavigation();

  return async nextUrl => {
    trackEvent('Website: Logout');
    if (enabledAuth0MyAccount) {
      myZegoLogout();
    }

    await signOut();
    await resetSession(environment);
    reset();

    if (nextUrl) {
      navigation.replace({ href: nextUrl }, { query: { loggedOut: 'true' } });
    }
  };
}

export default useLogout;
