import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { Network as NetworkType } from 'relay-runtime/lib/network/RelayNetworkTypes';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';

import { NextApiRequest } from 'next';
import getConfig from 'next/config';

import fetch from 'isomorphic-unfetch';

import { getAuthReferrerCookie, getAuthToken } from '@zego/auth';
import { isBrowser } from '@zego/utils';

type Headers = Record<string, string>;

const config = getConfig();

let publicRuntimeConfig;

if (config) {
  publicRuntimeConfig = config.publicRuntimeConfig;
}

let relayEnvironment: Environment;

const getSharedHeaders = (): Headers => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    APP_ID: 'WEBSITE-2.0.0',
    'X-Zego-Accept-Sessions': 'Yes',
  };
};

const getHeaders = (
  token: string | null,
  authReferrer: string | null,
): Headers => ({
  ...getSharedHeaders(),
  ...(token && { Authorization: token }),
  ...(authReferrer && { 'Auth-Referrer': authReferrer }),
});

export const getClientHeaders = (): Headers => {
  const token = getAuthToken();
  const authReferrer = getAuthReferrerCookie();

  return getHeaders(token, authReferrer);
};

const getServerHeaders = (request: NextApiRequest) => (): Headers => {
  const token = request.cookies?.zego_authtoken || null;
  const authReferrer = request.cookies?.auth_referrer || null;

  return getHeaders(token, authReferrer);
};

function getFetchQuery(getHeadersFn: () => Headers) {
  function fetchQuery(operation, variables, cacheConfig, uploadables) {
    return fetch(
      isBrowser()
        ? publicRuntimeConfig.GRAPHQL_ENDPOINT
        : process.env.INTERNAL_GRAPHQL_ENDPOINT
        ? process.env.INTERNAL_GRAPHQL_ENDPOINT
        : process.env.GRAPHQL_ENDPOINT,
      {
        method: 'POST',
        headers: getHeadersFn(),
        body: JSON.stringify({
          query: operation.text, // GraphQL text from input
          variables,
        }),
      },
    ).then(response => response.json());
  }

  return fetchQuery;
}

function createEnvironment(
  network: NetworkType,
  records?: RecordMap,
): Environment {
  const recordSource = new RecordSource(records);
  const store = new Store(recordSource);

  return new Environment({
    network,
    store,
  });
}

interface InitEnvironmentArgs {
  records?: RecordMap;
  request?: NextApiRequest | null;
}

export default function initEnvironment({
  records = {},
  request = null,
}: InitEnvironmentArgs): Environment {
  // Make sure to create a new Relay environment for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowser()) {
    const network = Network.create(
      getFetchQuery(request ? getServerHeaders(request) : getSharedHeaders),
    );
    return createEnvironment(network);
  }

  // reuse Relay environment on client-side
  if (!relayEnvironment) {
    const network = Network.create(getFetchQuery(getClientHeaders));
    relayEnvironment = createEnvironment(network, records);
  }

  return relayEnvironment;
}
