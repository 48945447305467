import React from 'react';

import I18nProvider from '@zego/i18n/I18nProvider';
import { getLangFromCountry } from '@zego/i18n/utils';
import Error from '@zego/pageContainers/Error';
import sentry from '@zego/sentry';
import { getCountryFromPath } from '@zego/utils';

const { captureException } = sentry();

const ZegoError = ({ statusCode, country, hasGetInitialPropsRun, err }) => {
  // Workaround for https://github.com/vercel/next.js/issues/8592
  // getInitalProps is not called when error is thrown at top level of module
  if (err && !hasGetInitialPropsRun) {
    captureException(err);
  }

  return (
    <I18nProvider language={getLangFromCountry(country)}>
      <Error statusCode={statusCode} country={country} />
    </I18nProvider>
  );
};

ZegoError.getInitialProps = ctx => {
  const { req, res, err } = ctx;

  const statusCode = res?.statusCode || null;
  const country = getCountryFromPath(req?.originalUrl);
  const hasGetInitialPropsRun = true;

  if (err) {
    captureException(err, ctx);
  }
  return { statusCode, country, hasGetInitialPropsRun, err };
};

export default ZegoError;
