import { Environment, fetchQuery, graphql } from 'react-relay';

import { addMinutes } from 'date-fns';
import { NextPageContext } from 'next';

import ServerCookies from 'cookies';
import Cookies from 'js-cookie';

import { auth_refreshAuthTokenQueryResponse } from '@zego-types/auth_refreshAuthTokenQuery.graphql';

export const AUTH_COOKIE_NAME = 'zego_authtoken';
// This is used in MagicLinkLoginPage
export const AUTH_COOKIE_EXPIRES_DAYS = 14;

const AUTH_REFERRER_COOKIE_NAME = 'auth_referrer';

export const getExpiryTime = (token: string): Date | null => {
  try {
    return new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
  } catch {
    return null;
  }
};

export const setAuthToken = (token: string): void => {
  if (!token) {
    return;
  }
  Cookies.set(AUTH_COOKIE_NAME, token, {
    expires: getExpiryTime(token) || AUTH_COOKIE_EXPIRES_DAYS,
  });
};

export const clearAuthToken = (): void => Cookies.remove(AUTH_COOKIE_NAME);

export const getAuthToken = (): string => Cookies.get(AUTH_COOKIE_NAME);

export const refreshAuthToken = async (
  environment: Environment,
): Promise<void> => {
  const query = graphql`
    query auth_refreshAuthTokenQuery {
      viewer {
        session {
          token
        }
      }
    }
  `;
  try {
    const {
      viewer: {
        session: { token },
      },
    } = (await fetchQuery(
      environment,
      query,
      {},
    )) as auth_refreshAuthTokenQueryResponse;

    setAuthToken(token);
  } catch (e) {
    console.error('failed to refresh token:', e);
  }
};

export const setAuthReferrerCookie = (
  referrerName: string,
  token: string,
  ctx?: NextPageContext,
): void => {
  const THIRTY_MINUTES = addMinutes(new Date(), 30);
  const cookieInterface = ctx
    ? new ServerCookies(ctx?.req, ctx?.res)
    : Cookies;

  cookieInterface.set(AUTH_REFERRER_COOKIE_NAME, referrerName, {
    expires: getExpiryTime(token) || THIRTY_MINUTES,
    httpOnly: false,
  });
};

export const getAuthReferrerCookie = (ctx?: NextPageContext): string => {
  const cookieInterface = ctx
    ? new ServerCookies(ctx?.req, ctx?.res)
    : Cookies;

  return cookieInterface.get(AUTH_REFERRER_COOKIE_NAME);
};

export const clearAuthReferrerCookie = (ctx?: NextPageContext): void => {
  const cookieInterface = ctx
    ? new ServerCookies(ctx?.req, ctx?.res)
    : Cookies;

  return cookieInterface.remove(AUTH_REFERRER_COOKIE_NAME);
};
