import React from 'react';

import { withTheme } from 'emotion-theming';

const ArrowLinkDecoration = ({ color, isBack, theme }) => (
  <svg width="17px" height="6px" viewBox="0 0 17 6" css={{ display: 'block' }}>
    <path
      d="M15.291728,3.67 L0.5,3.67 C0.223857625,3.67 0,3.44614237 0,3.17 C0,2.89385763 0.223857625,2.67 0.5,2.67 L15.267767,2.67 L13.3048737,0.707106781 L14.0119805,0 L16.8404076,2.82842712 C17.0356698,3.02368927 17.0356698,3.34027176 16.8404076,3.53553391 L14.0119805,6.36396103 L13.3048737,5.65685425 L15.291728,3.67 Z"
      transform={isBack && 'translate(17), scale(-1, 1)'}
      fill={color || theme.tintColor}
      fillRule="nonzero"
    />
  </svg>
);

export default withTheme(ArrowLinkDecoration);
