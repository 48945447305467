// NOTE: This require will be replaced with `@sentry/browser` in browser
import getConfig from 'next/config';

import * as Integrations from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import Cookie from 'js-cookie';

import { isBrowser } from '@zego/utils';

const { publicRuntimeConfig } = getConfig();

module.exports = () => {
  const sentryOptions = {
    dsn: publicRuntimeConfig.SENTRY_DSN,
    release: publicRuntimeConfig.SENTRY_RELEASE,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
  };

  // When we're developing locally
  if (process.env.NODE_ENV !== 'production') {
    // Don't actually send the errors to Sentry
    sentryOptions.beforeSend = () => null;

    // Instead, dump the errors to the console
    sentryOptions.integrations = [
      new Integrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ];
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    captureException: (err, { req, res, errorInfo, query, pathname } = {}) => {
      Sentry.configureScope(scope => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode);
        }

        if (res && res.statusCode) {
          scope.setExtra('statusCode', res.statusCode);
        }

        if (isBrowser()) {
          scope.setTag('ssr', false);
          scope.setExtras({
            query,
            pathname,
          });

          // On client-side we use js-cookie package to fetch it
          const sessionId = Cookie.get('sid');
          if (sessionId) {
            scope.setUser({ id: sessionId });
          }
        } else {
          scope.setTag('ssr', true);
          if (req) {
            scope.setExtras({
              url: req.url,
              method: req.method,
              headers: req.headers,
              params: req.params,
              query: req.query,
            });

            // On server-side we take session cookie directly from request
            if (req.cookies.sid) {
              scope.setUser({ id: req.cookies.sid });
            }
          }
        }

        if (errorInfo) {
          scope.setExtras(errorInfo);
        }
      });

      return Sentry.captureException(err);
    },
  };
};
