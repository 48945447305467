import { css } from '@emotion/core';

export const modernEraFamily =
  '"Modern Era", "Helvetica Neue", "Helvetica", "Arial", sans-serif;';

export const BOX_SHADOW = [
  '0px 0px 0px rgba(0, 0, 0, 0)',
  '0px 4px 8px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.1)',
  '0px 10px 16px rgba(42, 42, 42, 0.2)',
  '0px 16px 24px rgba(42, 42, 42, 0.2)',
];

export const PALETTE = {
  red: {
    1: '#FFF4F3',
    2: '#FFD7D2',
    3: '#FF9385',
    4: '#FB6450',
    5: '#EB4833',
    6: '#C71C05',
  },
  green: {
    1: '#EDFBEE',
    2: '#CAF7D6',
    3: '#ABEDBA',
    4: '#7CE394',
    5: '#42D764',
    6: '#13AE38',
    7: '#46E18C',
  },
  blue: {
    1: '#E5F5FF',
    2: '#D3EDFF',
    3: '#9ED7FF',
    4: '#5CBCFF',
    5: '#33AAFF',
    6: '#0686E0',
  },
  orange: {
    1: '#FEF6EC',
    2: '#FFDD99',
    3: '#FFCC66',
    4: '#FFBB33',
    5: '#FFAA00',
    6: '#F58F00',
  },
  purple: {
    1: '#F6F5FF',
    2: '#F7F5FF',
    3: '#EBE7FE',
    4: '#DFD8FD',
    5: '#CFC5FC',
    6: '#A794F9',
    7: '#886EEC',
    8: '#6E51DB',
    9: '#5A37D7',
    10: '#4A26BF',
    11: '#371987', //Zego purple
    12: '#2A2264',
    13: '#1F194B',
    14: '#151132',
  },
  grey: {
    1: '#FFFFFF',
    2: '#F7F7F8',
    3: '#F2F1F3',
    4: '#DFDDE3',
    5: '#D1CFD8',
    6: '#B7B4C1',
    7: '#8C879C',
    8: '#615C70',
    9: '#494554',
    10: '#27252D',
  },
};
export const getColorTint = (color, index = 8) => PALETTE[color][index];

export const primaryColor = index => getColorTint('purple', index);
export const infoColor = index => getColorTint('blue', index);
export const errorColor = index => getColorTint('red', index);
export const successColor = index => getColorTint('green', index);
export const warningColor = index => getColorTint('orange', index);
export const disabledColor = index => getColorTint('grey', index);

export const white = '#FFFFFF';
export const black = '#000000';

// These colours come from Trustpilot
export const TRUST_PILOT_COLOURS = [
  '#FF3722', // 0 is never used
  '#FF3722', // 1
  '#FF8623', // 2
  '#FFCE03', // 3
  '#72CF12', // 4
  '#00B67A', // 5
];

export const UBER_COLOURS = {
  green: '#06C167',
  gold: '#FFC043',
  platinum: '#8EA3AD',
};

export const baseType = css({
  fontFamily: modernEraFamily,
  WebkitFontSmoothing: 'antialiased',
});

export const baseText = css({
  fontFamily: modernEraFamily,
  fontWeight: 500,
  fontSize: '16px',
  color: disabledColor(8),
  lineHeight: '24px',
  WebkitFontSmoothing: 'antialiased',
});

export const BREAKPOINTS = {
  verySmallOnly: '@media only screen and (max-width: 350px)',
  smallOnly: '@media only screen and (max-width: 512px)',
  small: '@media only screen and (min-width: 512px)',
  medium: '@media only screen and (min-width: 768px)',
  normal: '@media screen and (min-width: 1024px)',
  large: '@media screen and (min-width: 1200px)',
  contentContainer: '@media screen and (min-width: 1080px)',
};

export const SC_MAX_CONTENT_WIDTH = 1180;

export const MAX_CONTENT_WIDTH = 1080;

export const OUTER_GUTTER = 30;
export const SC_OUTER_GUTTER = 36;
export const INNER_GUTTER = 20;
export const HALF_INNER_GUTTER = INNER_GUTTER / 2;

export const SMALL_NUM_COLUMNS = 6;
export const MEDIUM_NUM_COLUMNS = 12;
export const LARGE_NUM_COLUMNS = 12;

export const SIZE_UNIT = 8;

export const getColWidthCalc = (
  t /*: number*/,
  g /*: number */,
  n /*: number */,
) => {
  /*
  t: number of columns there are, so will be 12 on large screen, 7 on small.

  g: The space between the cols. So 20.

  n: the output width of columns you want
  if t = 12 and g is 1 space n will be the number of columns the width to be
  With n being 3 the [] show the width
  [| | |] | | | | | | | | |

  https://imgur.com/a/E6Jjc6x
  */
  return `calc((((100% - ((${t} - 1) * ${g}px)) / ${t}) * ${n}) + ((${n} - 1) * ${g}px))`;
};

export const unit = (multiplier /*: number */) => multiplier * SIZE_UNIT;

// Unit from top, right, bottom left. follows the same as CSS
// When two values it is vertical + horizontal
export const unitTRBL = (...args) =>
  args
    .map(multiplier =>
      typeof multiplier === 'number'
        ? `${multiplier * SIZE_UNIT}px`
        : multiplier,
    )
    .join(' ');
