import styled from '@emotion/styled';

import { BREAKPOINTS, baseText, disabledColor } from '@zego/theme';

const H1 = styled.h1(baseText, {
  fontWeight: 700,
  fontSize: 42,
  color: disabledColor(10),
  lineHeight: 0.96,
  letterSpacing: -2,
  [BREAKPOINTS.medium]: {
    fontSize: 62,
  },
});

export default H1;
