import React from 'react';
import { useUIDSeed } from 'react-uid';

import { Form as FormikForm } from 'formik';

export const FormContext = React.createContext({});

export default function Form(props) {
  const seed = useUIDSeed();

  return (
    <FormContext.Provider value={seed}>
      <FormikForm {...props} />
    </FormContext.Provider>
  );
}
