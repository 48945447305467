import { useEffect } from 'react';

export default function useWindowEvent<K extends keyof WindowEventMap>(
  event: K,
  handler: (this: Window, ev: WindowEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions,
): void {
  useEffect(() => {
    window.addEventListener(event, handler, options);
    return function cleanup(): void {
      window.removeEventListener(event, handler);
    };
  }, [event, handler, options]);
}
