import { useCallback, useContext } from 'react';

import { identifyUser, reset, trackEvent, trackPageview } from './tracking';
import TrackingContext from './TrackingContext';

function getContextPayload(trackingContext) {
  if (!trackingContext) return {};

  return Object.keys(trackingContext).reduce((acc, key) => {
    acc[`$${key}`] = trackingContext[key];
    return acc;
  }, {});
}

export default function useTracking() {
  const trackingContext = useContext(TrackingContext);

  const contextualTrackEvent = useCallback(
    (name, payload) =>
      trackEvent(name, {
        ...getContextPayload(trackingContext),
        ...payload,
      }),
    [trackingContext],
  );

  return {
    trackEvent: contextualTrackEvent,
    trackPageview,
    reset,
    identifyUser,
    trackingContext,
  };
}
