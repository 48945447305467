import { useContext } from 'react';

import { useRouter } from 'next/router';

import PageContext from '@zego/context/PageContext';

const useNavigation = () => {
  const { country } = useContext(PageContext);
  const router = useRouter();

  const navigate = (
    { href, as = null },
    { query = {}, shallow = false } = {},
    operation,
  ) => {
    const url = {
      pathname: href,
      query: { ...query, country },
    };

    router[operation](url, as ? as : href, { shallow }).then(() => {
      window.scrollTo(0, 0);
    });
  };

  return {
    push: ({ href, as = null }, { query = {}, shallow = false } = {}) => {
      navigate({ href, as }, { query, shallow }, 'push');
    },
    replace: ({ href, as = null }, { query = {}, shallow = false } = {}) => {
      navigate({ href, as }, { query, shallow }, 'replace');
    },
    location: router.location,
    query: router.query,
  };
};

export default useNavigation;
