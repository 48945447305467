import * as React from 'react';
import { ReactRelayContext, RelayContext } from 'react-relay';

export default function useRelay(): RelayContext {
  const ctx = React.useContext(ReactRelayContext);
  if (ctx == null) {
    // Should not happen but if we throw then it will get picked up in Sentry
    throw new TypeError('RelayContext not found');
  }
  return ctx;
}
