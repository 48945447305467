import { useEffect } from 'react';

import usePrevious from '@zego/hooks/usePrevious';
import { useTracking } from '@zego/tracking';
import { OptanonWrapper } from '@zego/utils/OneTrustUtils';

export default function PageBootstrap() {
  const { trackingContext, trackPageview } = useTracking();

  const prevUrlPath = usePrevious(trackingContext.urlPath);
  const prevUrlQueryString = usePrevious(trackingContext.urlQueryString);

  useEffect(() => {
    // If any part of the URL has changed EXCEPT for the hash, we want to track a pageview
    if (
      trackingContext.urlPath !== prevUrlPath ||
      trackingContext.urlQueryString !== prevUrlQueryString
    ) {
      trackPageview({
        pathname: trackingContext.urlPath,
        search: trackingContext.urlQueryString,
        hash: trackingContext.urlHash,
      });
    }
  }, [
    prevUrlPath,
    prevUrlQueryString,
    trackPageview,
    trackingContext.urlHash,
    trackingContext.urlPath,
    trackingContext.urlQueryString,
  ]);

  useEffect(() => {
    window.addEventListener('load', () => {
      OptanonWrapper();
    });
  });

  return null;
}
