import React from 'react';

import { I18nProvider as BaseI18nProvider } from '@lingui/react';
import { I18n } from '@lingui/react';

import catalogEn from '@zego/locale/en/messages';
import catalogEs from '@zego/locale/es/messages';
import catalogFr from '@zego/locale/fr/messages';
import catalogNl from '@zego/locale/nl/messages';

import I18nContext from './I18nContext';

const catalogs = {
  en: catalogEn,
  es: catalogEs,
  fr: catalogFr,
  nl: catalogNl,
};

const I18nProvider = ({ language, children }) => (
  <BaseI18nProvider language={language} catalogs={catalogs}>
    <I18n>
      {value => (
        <I18nContext.Provider value={value}>{children}</I18nContext.Provider>
      )}
    </I18n>
  </BaseI18nProvider>
);

export default I18nProvider;
