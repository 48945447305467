import styled from '@emotion/styled';

import { BREAKPOINTS } from '@zego/theme';

interface SpacerProps {
  height?: number | string;
  width?: number | string;
  mobileHeight?: number | string;
}

const Spacer = styled('div')<SpacerProps>(
  ({ height, width, mobileHeight }) => {
    const widthAmount = width || 0;
    const heightAmount = height || 0;
    return {
      width: widthAmount,
      height: mobileHeight ? mobileHeight : heightAmount,
      [BREAKPOINTS.medium]: {
        height: heightAmount,
      },
    };
  },
);

export default Spacer;
