import { COUNTRY_MAP, LOCALE_FORMAT, LOCALE_MAP } from './constants';

/**
 * Get the language based on country
 * @param {?string} country ISO 3166 Alpha-2 country code
 * @return {string} ISO 639-1 Alpha-2 language code, defaults to 'en'
 */
export function getLangFromCountry(country) {
  const lang = COUNTRY_MAP[country];

  if (!lang || !country) return 'en';

  return lang;
}

/**
 * Get the locale based on the country
 * @param {?string} country ISO 3166 Alpha-2 country code
 * @param {'IETF' | 'ISO'} format Format as '-' (IETF) or '_' (ISO)
 * @return locale delimited by '-' i.e. gb
 * @example getLocaleFromCountry('gb') // en-gb
 * @example getLocaleFromCountry('gb', 'ISO') // en_GB
 */
export function getLocaleFromCountry(country, format = LOCALE_FORMAT.IETF) {
  const locale = LOCALE_MAP[country];

  if (!locale || !country) return 'en';

  if (format === LOCALE_FORMAT.IETF) {
    return locale.split('_').join('-').toLowerCase();
  }

  return locale;
}
