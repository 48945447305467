export function clearDup() {
  const sec = document.getElementById('ot-sdk-cookie-policy');
  const tally = [];

  if (!sec) {
    return;
  }

  for (var i = sec.length - 1; i >= 0; i--) {
    if (tally[sec[i].firstChild.innerText] === undefined) {
      tally[sec[i].firstChild.innerText] = 1;
    } else {
      sec[i].remove();
    }
  }
}

export function reloadOTBanner() {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (window.OneTrust != null) {
    // eslint-disable-next-line no-undef
    OneTrust.Init();

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      OneTrust.LoadBanner();

      const toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings',
      );

      for (var i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function (event) {
          event.stopImmediatePropagation();
          window.OneTrust.ToggleInfoDisplay();
        };
      }
    }, 1000);
  }
}

export function OptanonWrapper() {}
